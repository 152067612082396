<template>
  <v-container id="user-profile-view" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="6">
        <material-zard color="primary" icon="mdi-card-text-outline">
          <template #title v-if="course">
            {{ course.name }} —
            <small class="text-body-1">Агуулгууд </small>
          </template>
          <v-expansion-panels v-model="panel" multiple v-if="topics">
            <v-expansion-panel
              v-for="(topic, index) in topics"
              v-bind:key="index"
            >
              <v-expansion-panel-header
                >{{ index + 1 }}.
                <span class="primary--text">
                  {{ topic.name }}, {{ topic.id }}</span
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-list three-line v-if="topic.contents">
                  <v-list-item
                    v-for="(content, i) in topic.contents"
                    :key="i"
                    ripple
                    @click="_openContentDialog(topic, index, content, i)"
                  >
                    <v-img
                      src="/course-test.jpg"
                      class="mr-4"
                      max-width="64"
                      min-width="64"
                    ></v-img>
                    <v-list-item-content>
                      <span
                        class="text-uppercase font-weight-regular caption"
                        >{{ content.name }}</span
                      >
                      <div>{{ content.contenturl }}</div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-expansion-panel-content>
              <v-toolbar flat>
                <v-btn
                  color="secondary"
                  @click="_openContentDialog(topic, index)"
                >
                  Видео+
                </v-btn>

                <v-spacer />
                <v-btn icon @click="_deleteTopic(topic, index)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>

                <v-btn icon @click="_addTopic">
                  <v-icon>mdi-plus-circle-outline</v-icon>
                </v-btn>

                <v-btn icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </v-toolbar>
            </v-expansion-panel>
          </v-expansion-panels>
        </material-zard>
      </v-col>
      <v-col cols="12" md="4">
        <app-card class="mt-4 text-center">
          <v-card-text class="text-center" v-if="course">
            <h6 class="text-h6 mb-2 text--secondary">{{ course.name }}</h6>

            <h4 class="text-h4 mb-3 text--primary">TITLE {{ courseId }}</h4>

            <p class="text--secondary">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione
              dolorem deserunt veniam tempora magnam quisquam quam error iusto
              cupiditate ducimus, et eligendi saepe voluptatibus assumenda
              similique temporibus placeat animi dicta?
            </p>

            <v-btn
              class="mr-0"
              color="secondary"
              min-width="100"
              rounded
              @click="_addTopic"
            >
              Сэдэв+
            </v-btn>
          </v-card-text>
        </app-card>
        <app-card class="mt-4 text-center">
          <v-card-text class="text-center" v-if="course">
            <h6 class="text-h6 mb-2 text--secondary">{{ course.name }}</h6>

            <h4 class="text-h4 mb-3 text--primary">TITLE {{ courseId }}</h4>

            <p class="text--secondary">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ratione
              dolorem deserunt veniam tempora magnam quisquam quam error iusto
              cupiditate ducimus, et eligendi saepe voluptatibus assumenda
              similique temporibus placeat animi dicta?
            </p>

            <v-btn
              class="mr-0"
              color="secondary"
              min-width="100"
              rounded
              @click="_addResult"
            >
              Үр дүн+
            </v-btn>
          </v-card-text>
          <v-card-text class="text-left">
            <v-list three-line v-if="course && course.results">
              <v-list-item
                v-for="(result, index) in course.results"
                v-bind:key="index"
                ripple
                @click="_openResultDialog(result, index)"
              >
                <v-list-item-content>
                  <span class="text-uppercase font-weight-regular caption">{{
                    result.name
                  }}</span>
                  <div>{{ result.description }}</div>
                </v-list-item-content>

                <v-list-item-icon>
                  <v-icon small class="mr-2" @click.stop="editItem(item)">
                    mdi-pencil
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list>
          </v-card-text>
        </app-card>
      </v-col>
    </v-row>

    <v-dialog v-model="addContentDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline"> Видео xичээл нэмэx </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedContent.name"
                  label="Нэр оруулаx"
                />
              </v-col>

              <v-col cols="12">
                <p class="text-wrap text--primary">
                  IFrame линк xуулж энд байршуулна уу /Жиш: Google Slides,
                  Microsoft Powerpoint .../
                </p>
                <v-textarea
                  v-model="editedContent.contenturl"
                  outlined
                  label="Линкээ xуулаад энд байршуулна уу (Copy-Paste)"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12"> </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn color="error" class="white--text" @click="_deleteContent">
            Устгаx
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="addContentDialog = !addContentDialog"
          >
            Цуцлаx
          </v-btn>
          <v-btn color="blue darken-1" text @click="_saveContent()">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addResultDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline">Үр дүн нэмэx</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editedResult.name"
                  label="Үр дүн оруулаx"
                />
              </v-col>

              <v-col cols="12">
                <p class="text-wrap text--primary">Тайлбар</p>
                <v-textarea
                  v-model="editedResult.description"
                  outlined
                  label="Үр дүнгийн тайлбараа бичнэ үү."
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12"> </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="addResultDialog = !addResultDialog"
          >
            Цуцлаx
          </v-btn>
          <v-btn color="blue darken-1" text @click="_saveResult()">
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Topic from "./Topic";
const fb = require("../../firebaseConfig.js");
export default {
  name: "CourseDetail",
  components: {
    Topic,
  },
  data: () => ({
    panel: [0, 1],
    course: null,
    topics: null,
    addContentDialog: false,
    defaultContent: {
      name: "",
      contenturl: "",
      topicId: "",
      topicIndex: "",
    },
    editedContent: {
      name: "",
      contenturl: "",
      topicId: "",
      topicIndex: "",
    },
    editedResult: {
      name: "",
      description: "",
    },
    addResultDialog: false,
  }),
  props: {
    courseId: {
      type: String,
      required: true,
    },
  },
  methods: {
   
    _saveResult() {
      var changedItem = this.course.results[this.editedResult.index];
      console.log(this.editedResult);
      console.log(changedItem);
      changedItem.name = this.editedResult.name;
      changedItem.description = this.editedResult.description;

      fb.db
        .collection("courses")
        .doc(this.course.id)
        .update(this.course)
        .then(()=>{
          this.addResultDialog = false
        });
    },
    _openResultDialog(result, index) {
      this.editedResult = Object.assign({}, result);
      this.editedResult.index = index;
      this.addResultDialog = true;
    },
    _openContentDialog(topic, index, content, contentIndex) {
      console.log(topic.id, index);
      if (content) {
        content.topicId = topic.id
        content.topicIndex = index
        content.contentIndex = contentIndex
        this.editedContent = Object.assign({}, content)

      } else {
        this.defaultContent.topicId = topic.id;
        this.defaultContent.topicIndex = index;
        this.editedContent = Object.assign({}, this.defaultContent)
      }
      this.addContentDialog = true;
    },
    _closeAddContentDialog() {
      this.$nextTick(() => {
        // this.editedItem = Object.assign({}, this.defaultItem)
        // this.editedIndex = -1
      });
    },
     _deleteContent(){
      var yes = confirm(this.editedContent.name + " delete?");
      if(yes){
        this.editedContent.ref.delete().then(()=>{
             // confirm(this.editedContent.name + " DELETED!!!")
          this.addContentDialog = false
          var selectedTopic = this.topics[this.editedContent.topicIndex]
          if(selectedTopic)
            selectedTopic.contents.splice(this.editedContent.contentIndex,1)
        }
        ).catch()
      }
    },
    _saveContent() {
      fb.db
        .collection("courses")
        .doc(this.course.id)
        .collection("topics")
        .doc(this.editedContent.topicId)
        .collection("details")
        .doc()
        .set(this.editedContent)
        .then(() => {
          console.log(
            this.course.id +
              "/topics/" +
              this.editedContent.topicId +
              "/details"
          );
          this.topics[this.editedContent.topicIndex].contents.push(
            this.editedContent
          );
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });

      // var docRef =
      // docRef.update({ slideUrl: this.asuulga.slideUrl, showWithSlide: withSlide})
      this.addContentDialog = false;
    },
    _addContent(topic, index) {
      var newContent = {
        name: "XXATAG",
        iframeurl: "ddd",
        createdAt: new Date(),
      };
      fb.db
        .collection("courses")
        .doc(this.course.id)
        .collection("topics")
        .doc(topic.id)
        .collection("details")
        .doc()
        .set(newContent)
        .then(() => {
          console.log(this.course.id + "/topics/" + topic.id + "/details");
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    _addResult() {
      this.course.results.push({ name: "text", description: "description" });
      fb.db.collection("courses").doc(this.course.id).update(this.course);
    },
    _deleteTopic(item, index) {
      fb.db
        .collection("courses")
        .doc(this.courseId)
        .collection("topics")
        .doc(item.id)
        .delete()
        .then(() => {
          console.log("Document successfully deleted! - ", index);
          this.topics.splice(index, 1);
        })
        .catch((error) => {
          console.error("Error removing document: ", error);
        });
    },
    _addTopic() {
      var newItem = {
        name: "New item",
        date: new Date(),
      };
      fb.db
        .collection("courses")
        .doc(this.courseId)
        .collection("topics")
        .doc()
        .set(newItem)
        .then(() => {
          this.topics.push(newItem);
        })
        .catch((error) => {
          console.error("Error writing document: ", error);
        });
    },
    async _readContent(topic) {
      fb.db
        .collection("courses")
        .doc(this.courseId)
        .collection("topics")
        .doc(topic.id)
        .collection("details")
        // .where("capital", "==", true)
        .get()
        .then((querySnapshot) => {
          topic.contents = [];
          querySnapshot.forEach((doc) => {
            let content = doc.data();
            content.id = doc.id;
            content.ref = doc.ref;
            topic.contents.push(content);
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    async _readTopics(course) {
      fb.db
        .collection("courses")
        .doc(this.courseId)
        .collection("topics")
        // .where("capital", "==", true)
        .get()
        .then((querySnapshot) => {
          this.topics = [];
          querySnapshot.forEach(async (doc) => {
            let topic = doc.data();
            topic.id = doc.id;
            topic.ref = doc.ref;
            await this._readContent(topic);
            this.topics.push(topic);
          });
        })
        .catch((error) => {
          console.log("Error getting documents: ", error);
        });
    },
    _readCourse() {
      var docRef = fb.db.collection("courses").doc(this.courseId);
      docRef
        .get()
        .then(async (doc) => {
          if (doc.exists) {
            let course = doc.data();
            course.id = doc.id;
            course.ref = doc.ref;
            this.course = course;
            if (!this.course.results) this.course.results = [];
            await this._readTopics(course);
          } else {
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
        });
    },
  },
  created() {
    this._readCourse();
  },
};
</script>
